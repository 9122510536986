/* styles.css */
.wholePage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

}

.containerContent {
  margin-top: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


}

/* ******************************************** */
/* 
In caz ca nu mai merg floating buttons astea merg f bine

.floatingButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    
}


.floatingButtons {
    display: flex;
    position: fixed;
    top: 130px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  
    transition: 3s ease-in;
    transition: 2s ease-in-out;
  
    width:100%;
} */

/* **************************************************** */


.floatingButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
}

.floatingButtons {
  display: flex;
  position: fixed;

  top: 180px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 100;

  transition: 3s ease-in;
  transition: 2s ease-in-out;
  z-index: 100;
  width: 100%;
}

@keyframes topToLeft {
  to {
    transform: translate(-48%, 0);
    transition: left 2.0s ease-in-out;
    -webkit-transition: left 2.0s ease-in-out;
    -moz-transition: left 2.0s ease-in-out;
    -ms-transition: left 2.0s ease-in-out;
    -o-transition: left 2.0s ease-in-out;
    -webkit-transform: translate(-48%, 0);
    -moz-transform: translate(-48%, 0);
    -ms-transform: translate(-48%, 0);
    -o-transform: translate(-48%, 0);
  }
}

@media (max-width: 700px) {
  @keyframes topToLeft {
    to {
      transform: translate(-42%, 0);
      transition: left 2.0s ease-in-out;
      -webkit-transition: left 2.0s ease-in-out;
      -moz-transition: left 2.0s ease-in-out;
      -ms-transition: left 2.0s ease-in-out;
      -o-transition: left 2.0s ease-in-out;
      -webkit-transform: translate(-42%, 0);
      -moz-transform: translate(-42%, 0);
      -ms-transform: translate(-42%, 0);
      -o-transform: translate(-42%, 0);
    }
  }
}

.animated {
  justify-content: flex-start;

  flex-direction: column;
  animation-fill-mode: forwards;
  animation-name: topToLeft;
  animation-duration: 2s;
  z-index: 100;

}

/* Style for each individual button */

.submitStyle {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 16px;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.button2 {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #262626;
  border: 1px solid #262626;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.button2:hover {
  background-color: rgb(68, 64, 71);
  border-color: rgb(68, 64, 71);
}

.invoice{

  display: flex;
  align-items: center;
  justify-content: center;
  --width: 600px;
  --height: 500px;
 
}


.button {
  --width: 300px;
  --height: 50px;
  --transition-duration: 500ms;
  --transition-easing: ease-out;
  width: var(--width);
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 40px 60px;
  transition: 0.5s;
  color: #262626;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(135, 206, 235);
  backdrop-filter: blur(2px);
  box-shadow: 0px calc(var(--height) / 6) calc(var(--height) / 3) rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-duration) var(--transition-easing);
  overflow: hidden;

  &::before {
    content: '';
    background: rgba(255, 255, 255, 0.4);
    width: 60%;
    height: 100%;
    top: 0%;
    left: -125%;

    transform: skew(45deg);

    position: absolute;

    transition: left var(--transition-duration) var(--transition-easing);
  }

  &:hover {

    &::before {
      left: 150%;
    }
  }
}

.buttonScurt {
  --width: 50px;
  --height: 40px;
  --transition-duration: 500ms;
  --transition-easing: ease-out;
  width: var(--width);
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s;
  color: #262626;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(135, 206, 235);
  backdrop-filter: blur(2px);
  box-shadow: 0px calc(var(--height) / 6) calc(var(--height) / 3) rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-duration) var(--transition-easing);
  overflow: hidden;

  &::before {
    content: '';
    background: rgba(255, 255, 255, 0.4);
    width: 60%;
    height: 100%;
    top: 0%;
    left: -125%;

    transform: skew(45deg);

    position: absolute;

    transition: left var(--transition-duration) var(--transition-easing);
  }

  &:hover {

    &::before {
      left: 150%;
    }
  }
}


@media (max-width: 700px) {
  .button {
    font-size: 24px;
    min-width: 150px;
  }
}



/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  pointer-events: none;
  transition: all 0.3s ease;
}

.expanded {
  bottom: 0;
  right: 0;
}

.friendsContainer {
  max-height: 400px;
  /* Set the desired maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling when the content overflows */
  z-index: 999;
}

.friendsList {
  list-style: none;
  padding: 0;
  color: #fff;
}

.addFriendForm {
  margin-top: 20px;
}

.friendBox {
  width: 500px;
  height: 100px;
  border: 5px solid orange;
  background-color: transparent;

  position: absolute;
  /* Position the orange box absolutely within the .friendContainer */

}





* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


/* The icon styles, nested inside .container */
.main .container .floatingButton {
  --size: 75px;
  --transition-duration: 500ms;
  --transition-easing: ease-out;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;                  
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: white;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(41, 40, 42, 0.688);
  backdrop-filter: blur(2px);
  box-shadow:
    0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(0, 0, 0, 0.1);

  transition: transform var(--transition-duration) var(--transition-easing);

  overflow: hidden;

  &::before {
    content: '';
    background: rgba(255, 255, 255, 0.4);
    width: 60%;
    height: 100%;
    top: 0%;
    left: -125%;

    transform: skew(45deg);

    position: absolute;

    transition: left var(--transition-duration) var(--transition-easing);
  }

  &:hover {

    &::before {
      left: 150%;
    }
  }
}

.missionsContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}


.mission {
  display: flex;  
  align-items: center; 
  gap: 10px;
}


.progressBar {
  width: 400px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  flex: 1; 
}

 
.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  transition: width 0.3s ease-in-out;
}


.missionName {
  font-size: 18px;
  color: #ffffff;

}


.buttonDetailsContainer {
  display: flex;
  align-items: center;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  position: relative;
  color: black;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 80%; 
  max-width: 300px; 
}
.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.buttonDetails{
--size: 40px;
--transition-duration: 500ms;
--transition-easing: ease-out;

width: var(--size);
height: var(--size);

display: flex;
align-items: center;
justify-content: center;
border-radius: 10px;
text-decoration: none;
color: white;
font-size: 18px;
border: 1px solid rgba(255, 255, 255, 0.6);
background-color: rgba(41, 40, 42, 0.688);
backdrop-filter: blur(2px);
box-shadow:
  0px calc(var(--size) / 6) calc(var(--size) / 3) rgba(0, 0, 0, 0.1);

transition: transform var(--transition-duration) var(--transition-easing);

overflow: hidden;

&::before {
  content: '';
  background: rgba(255, 255, 255, 0.4);
  width: 60%;
  height: 100%;
  top: 0%;
  left: -125%;

  transform: skew(45deg);

  position: absolute;

  transition: left var(--transition-duration) var(--transition-easing);
}

&:hover {

  &::before {
    left: 150%;
  }
}
}

.balanceNumbers {

font-size: 5rem;
font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
color: #FFA500;
}

.balanceStatus {
display: flex;
justify-content: center;
font-size: 1.5rem;
color: #FFA500;
}


.containerTop {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center; /* Center items vertically */
}

.balanceThreeButtons {
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  padding: 20px; /* Example padding */
  margin: 10px; /* Example margin */
}

.buttonContainer {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: flex-end; /* Align buttons to the right */
  margin-left: 10px; /* Example margin */
}
