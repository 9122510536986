.container {
  display: flex;
   grid-gap: 60px;
  /* flex-direction: flex-start; */
  align-content: center;
  justify-content: center;
}

.firstRectangle
{
  display: flex;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px; */
  align-items: center;
  flex-direction: column;
  /* margin-left: 5%; */

}
  
.tabel{
    display: flex;
    justify-content: left;
    position: absolute;
    color: "orange";
    margin-left: 30px;
    margin-top: 500px;
}



  .secondRectangle{
    display: flex;
  justify-content: right;
  flex-direction: column;
  }

  .margins {
    margin-top: 100px;
    margin-left: -200px;
  }
  .headerMargin {
    margin-top: 20px;
  
  }
  .miniTaskbar{
    display: display-box;
    width: 50%;
    height: 500px;
    background: #cc8925;
  }

    /* .rightSide {
    margin-left: auto;
  } */
  @media (max-width: 700px) {
    body{
      zoom: 0.5;
  
    
   }
   .container{
    justify-content: center;
    flex-direction: column-reverse;
   }
   .secondRectangle{
    margin-left: 16%;
   }
   .firstRectangle{
    align-items: center;
   }
  }
  .rectangle {
    display: display-box;
    width: 50%;
    height: 1000px;
    background: black;
  }
