.wholePage {
  display: flex;

}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.startingToolbar {
  display: flex;
  justify-content: flex-start;
}

.textMiddle {
  display: flex;
  justify-content: center;
  font-size: 48px;
}
  .downloadPictures {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .individualDownloadPictures{
    height:200px;
    width:600px;
    zoom: 0.6;
    margin: 50px;
    margin-top: 100px;
  }
.textMiddleB {
  display: flex;
  justify-content: center;
  font-size: 48px;
}
.startingButtons {
  display: flex;
  gap: 70px;
  margin: 5rem auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.buttonSize {
  width: 100px;
  height: 100px;
}

.mapFlex {
  display: flex;
  justify-content: center;
  font-size: 48px;
}

.starting h1 {
  font-size: 3rem;
}

.referral {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonReferral {
  height: "50px" !important;
  width: "200px" !important;
}



@media (max-width: 1080px) {
  body {
    zoom: 0.5;

  }
.textMiddle{
  font-size: 34px;
}
.textMiddleB {
  font-size: 34px;
}
  .wholePage {
    justify-content: center;
    /* Center the content horizontally */
  }

  .photoSizeSmall {

    height: 50px;
    width: 50px;
  }

  .startingButtons {
    flex-wrap: wrap;
    /* Allow buttons to wrap on smaller screens */
    justify-content: center;
    /* Center the buttons horizontally */
    zoom: 0.7;
    margin: 2rem auto;
    /* Adjust the margin for better spacing */
  }


  .startingButtons button span {
    text-align: center;
  }



  /* Optionally, adjust font size and other styles for better mobile experience */


  /* Optionally, adjust font size for other elements if needed */
  .starting h1 {
    font-size: 3rem;
  }

  .starting h3 {
    font-size: 1.5rem;
  }

  .wholePage {
    justify-content: center;
    /* Center the content horizontally */
  }

  .startingButtons {
    flex-wrap: wrap;
    justify-content: center;
    zoom: 0.7;
    margin: 2rem auto;
    margin-top: 100px;
  }



  .startingButtons button span {
    text-align: center;
  }

  .mapFlex {
    font-size: 34px;
  }


  .startingButtons button span {

    text-align: center;

  }
}

@media (max-width: 1080px) {
  body {
    zoom: 0.8;
  }
}

@media (max-width: 700px) {

  .textMiddle{
    font-size: 40px;
  }

  .textMiddleB{
    font-size: 40px;
  }
}
  
@media (max-width: 460px) {
  .textMiddleB {
    font-size: 34px;
  }
  .individualDownloadPictures{
    zoom: 0.5;
  }
}

@media (max-width: 400px) {
  .textMiddleB {
    font-size: 30px;
  }
}

@media (max-width: 350px) {
  .textMiddleB {
    font-size: 25px;
  }
}
/* pt desktop sa nu fie butoanele de pe pagina principala prea mici  */

@media (min-width: 701px) {
  .startingButtons button {
    max-width: none;
  }
  .individualDownloadPictures{
    zoom:0.5;
  }

}





.animatedElementA {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 1.8s ease, transform 1.8s ease;
  -webkit-transition: opacity 1.8s ease, transform 1.8s ease;
  -moz-transition: opacity 1.8s ease, transform 1.8s ease;
  -ms-transition: opacity 1.8s ease, transform 1.8s ease;
  -o-transition: opacity 1.8s ease, transform 1.8s ease;
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
}

.animatedElementB {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  -webkit-transition: opacity 0.5s ease, transform 0.5s ease;
  -moz-transition: opacity 0.5s ease, transform 0.5s ease;
  -ms-transition: opacity 0.5s ease, transform 0.5s ease;
  -o-transition: opacity 0.5s ease, transform 0.5s ease;
}


@keyframes slideAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
}

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animatedSlideFade {
  animation: slideAndFadeIn 1.8s ease forwards;
  -webkit-animation: slideAndFadeIn 1.8s ease forwards;
}

.animatedAppearance {
  animation: appearance 3s ease forwards;
  -webkit-animation: appearance 3s ease forwards;
  transition: opacity 3s ease;
  -webkit-transition: opacity 3s ease;
  -moz-transition: opacity 3s ease;
  -ms-transition: opacity 3s ease;
  -o-transition: opacity 3s ease;
}



/* Style for the container div */
.containerPhotoFirst {
  display: flex;
  justify-content: flex-end;
  margin-right: 50vh;
  
}

.containerPhotoSecond{

  display: flex;
  justify-content: flex-start;
  margin-left: 50vh;
  margin-top: 20vh;

}

@media (max-width: 450px) {
  .containerPhotoFirst{
    zoom: 0.7;
    margin-right: 80px;
    margin-top: 100px;
  margin-bottom: 100px;
 }
 .containerPhotoSecond{
  zoom: 0.7;
    margin-right: 80px;
  margin-top: 250px;
  margin-bottom: 100px;
}
.textMiddle{
  font-size: 34px;
}

}

@media (max-width: 650px) {
  .containerPhotoFirst{
    zoom: 0.7;
    margin-right: 80px;
    margin-top: 100px;
  margin-bottom: 100px;
 }
 .containerPhotoSecond{
  zoom: 0.7;
    margin-right: 80px;
  margin-top: 250px;
  margin-bottom: 100px;
}
}

/* Style for the photos */
.photo {
  z-index: 1;
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  -ms-border-radius: 20%;
  -o-border-radius: 20%;
}

.lastPart
{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .curvedLine {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
