/* About.module.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
  }
  
  .centeredContent {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .logo {
    max-width: 100%;
    max-height: 20vh;
    /* margin-top: -30vh; */
  }
  
  .leftParagraph {
    margin-top: 20px;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .photoSection {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .roundPhoto {
    border-radius: 50%;
    max-width: 100%;
    max-height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .centeredParagraph {
    text-align: center;
    padding: 20px;
  }
  