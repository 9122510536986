.bigbox{
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
  justify-content: center;
}

.boxArticles{

  display:flex;
  justify-content: center;
}

.boxText{
  justify-items: center;
}
.articlebox {
  position: relative;
   width: 250px;  
  height: 150px;
  overflow: hidden; /* hide the overflow of the tilted title */
  cursor: pointer; /* make the article clickable */
  margin-top: 80px;
  margin-left:60px;  
  /* height: 1500px; */
  box-shadow: 5px 10px 20px #262626;
    border-radius: 10px;
  
}


.articleboxImg {
  width: 100%; /* make the image take the full width of the container */
  height: 100%; /* make the image take the full height of the container */
  /* object-fit: cover; make the image cover the container */
  
}

.articleboxH3 {
  position: absolute; /* position the title absolute */
  bottom: 0; /* position it at the bottom */
  left: 0; /* position it at the left */
 
  background-color: #87CEEB; /* set the background color */
  padding: 10px; /* add some padding */
  margin: 0; /* remove the margin */
  width: 250px; 
}


@media (max-width: 700px) {
  body {
    overflow-x: hidden;
  }
    .bigbox{
      flex-direction: column;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    font-family: "Arial", sans-serif;
    font-weight: bold;
    color: #FFFDFA;

  }
  
  .subtitle {
    font-family: "Arial", sans-serif;
    font-weight: bold;
    color: #FFFDFA;
    font-size: 24px;
  }
  
  .article {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .bodyText {
    font-family: "Georgia", serif;
    font-size: 16px;
    line-height: 1.6;
    color: #F0F0F0;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .paragraph {
    font-size: 20px;
    color: #F0F0F0;
  }

  li {
    color: #F0F0F0;
  }

  .buttonContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .pageNumber{
    font-size: 24px;
    color:#F0F0F0;
    
  }
  .pageButton {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #87CEEB; /* Adjust color as needed */
    background-color: transparent;
    cursor: pointer;
    position: relative;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .pageButton2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #87CEEB; /* Adjust color as needed */
    background-color: transparent;
    cursor: pointer;
    position: relative;

  }