.headerContainer{

  display: flex;
  justify-content: center;
}
.header {
  width: 90%;
  height: 8rem;
  margin-top: 3rem;
  background-color: #87CEEB;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  border-radius: 30px;
  /*Addroundedcornershere*/backdrop-filter: blur(3.3px);
  -webkit-backdrop-filter: blur(3.3px);
  border: 1px solid rgba(135, 206, 235, 1);

}



.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
  width: 150px;
}

.header a {
  text-decoration: none;
  color: #F2F2F2;
  font-weight: bold;
  font-size: 1.5rem;
}

.header button {
  font: inherit;
  background-color: transparent;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

/* Este vorba despre butonul de Login!!! */
.header a:hover {
  color: #402401;
}

.header button:hover {
  background-color: #402401;
  color: #404040;
}


.backButton {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent #87CEEB; /* Adjust color as needed */
  background-color: transparent;
  cursor: pointer;
  position: relative;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}



.logo,
.logo:after {
  font-family: 'Lato', sans-serif;
  font-size: 4rem;
  color: #262626;
  margin: 0;
  touch-action: manipulation;
  transition: all 0.5s ease;
}

@media (max-width: 700px) {
  .logoPoza {
    zoom: 0.75;
  }


}




/* .logo:after {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  
} */

/* .logo:after {
  content: '';
  background-image: url('../../images/logo bitbit.png');
  background-size: cover;
  background-position: center;
  display: block;
  position: absolute;
  transform: scale(0.2);
  top: 2.5%;
  margin-left: -2%;
  left: 0;
  right: 0;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
}

.logo:hover:after {
  animation-timing-function: steps(10, end);
  transform: translateX(155%);
  -webkit-transform: translateX(155%);
  -moz-transform: translateX(155%);
  -ms-transform: translateX(155%);
  -o-transform: translateX(155%);
} */
/* 
@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
    -webkit-transform: translate(-20px, -10px);
    -moz-transform: translate(-20px, -10px);
    -ms-transform: translate(-20px, -10px);
    -o-transform: translate(-20px, -10px);
}
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
    -webkit-transform: translate(-10px, 10px);
    -moz-transform: translate(-10px, 10px);
    -ms-transform: translate(-10px, 10px);
    -o-transform: translate(-10px, 10px);
}
  80% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
    -webkit-transform: translate(-10px, 10px);
    -moz-transform: translate(-10px, 10px);
    -ms-transform: translate(-10px, 10px);
    -o-transform: translate(-10px, 10px);
} */
/* 90% {
    clip-path: var(--slice-4);
    transform: translate(-10px, 0px);
    -webkit-transform: translate(-10px, 0px);
    -moz-transform: translate(-10px, 0px);
    -ms-transform: translate(-10px, 0px);
    -o-transform: translate(-10px, 0px);
} */
/* 100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  } */
/* } */

/* @media (max-width: 768px) {

  .logo,
  .logo:after {
    white-space: nowrap;
    width: 175px;
    height: 100px;
    line-height: 100px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
  }

} */




