
.blobContainer {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .blob {
    width: 300px;
    height: 220px;
    background-color: #ffffff; 
    border-radius: 50%;
    position: relative;

    
    box-shadow: 0px 10px 20px 2px rgba(0,255,255,0.7);
  }
  
  

  @media (max-width: 1080px) {
    .blob{
      zoom: 0.8;
    
   }
  }

  @media (min-width: 700px) {
    .blob{
      zoom: 0.5;
    
   }
  }

  @media (max-width: 600px) {
    .blob{
      zoom: 0.7;
    
   }
  }

  @media (min-width: 850px) {
    .blob{
      zoom: 0.6;
    
   }
  }
  .blobWrapper {
    margin: 40px;
  }

  @media (min-width: 1000px) {
    .blob{
      zoom: 0.7;
    
   }
  }
  @media (max-width: 550px) {
    .blob{
      zoom: 0.55;
    
   }
  }

  @media (max-width: 450px) {
    .blob{
      zoom: 0.4;
    
   }
   
  }

  @media (max-width: 360px)
  {

    .blob{
      zoom:0.38;
    }
  }

  @media (max-width: 350px)
  {

    .blob{
      zoom:0.3;
    }
  }

  .blobImg {
    width: 100%;
    height: 100%;
    object-fit: contain; 
  
  }