
.container {
    display:flex;
    margin-top: 60px;
    /* height: 1500px;  alungire pagina pt teste */
    justify-content: center;
}

.inputBoxes {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: none;
  border: none;
	border-bottom: solid 2px #FFA500;
	color: #FFA500;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
	margin: 0em 0 1.875em 0;
	padding: 0 0 0.875em 0;
	width: 550px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
    resize: none;
    
}
.inputBoxes [type='text']:focus, [type='email']:focus, textarea:focus {
	outline: none;

    border: none;
  outline: none;
}
.button {
margin-left: 25%;

    background: none;
    width: 250px;
    border: solid 2px #FFA500;
    color: #F2F2F2;
    cursor: pointer;
    display: inline-block;
    font-family: 'Helvetica', Arial, sans-serif;
    font-size: 0.875em;
    font-weight: bold;
    outline: none;
    padding: 20px 35px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      overflow: visible;
}

.font{
  font-family: 'Helvetica', Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    outline: none;
    
    color: #F2F2F2;
}

::-webkit-input-placeholder {
	color: #F2F2F2  ;
}
:-moz-placeholder { 
	color: #FFA500;
	opacity: 1;
}

::-moz-placeholder {
	color: #FFA500;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #FFA500;
}
.button:hover{
    background: #282c34;
    color: #F2F3EB;
}