@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');

.button2 {
    /* background-image:
    url('https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.nintendolife.com%2Fnews%2F2021%2F05%2Fpokemon_redrawn_is_a_pixel_art_project_thats_redesigning_johto_and_kanto&psig=AOvVaw1wfDLv8gjNPS2CzClUG1A5&ust=1671086088316000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCLD2j_i--PsCFQAAAAAdAAAAABAb');
    background-size: cover;
    background-position: center; */
    width: 80px;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    animation: falling 1s linear;
    -webkit-animation: falling 1s linear;
    animation-fill-mode: forwards;
    position: relative; 
  z-index: 1;
  text-align: center;
}


.button {
  margin: 10px;
  /* width: 300px;  */
  /* height: 100px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 40px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #262626;
  border-radius: 10px;
  border: 0px;
  font-weight: 700;
  font-size: 18px;
  /*box-shadow: 0px 0px 14px -7px #f09819;
  */background-image: linear-gradient(45deg, #FFA500  0%,  #FFDAB9 70%, #FFB347  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

  .button:hover{
  background-position: center;
  color: #404040;
  text-decoration: none;
}

.button:active {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
}

.subHeader {
  display: inline-block;
  width: 98%;
  height: 50px;
  background-color: #F28705;
  transform: skew(-30deg);
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
  padding: 0 5%;
}


.buttonMap {
  margin: 10px;
  /* width: 300px;  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #262626;
  border-radius: 10px;
  border: 0px;
  font-weight: 700;
  font-size: 10px;
  /*box-shadow: 0px 0px 14px -7px #f09819;
  */background-image: linear-gradient(45deg, #87CEEB  0%,  #91cbe2 70%, #abdff4  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

  .buttonMap:hover{
  background-position: center;
  color: #404040;
  text-decoration: none;
}

.buttonMap:active {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
}


@media (max-width: 700px) {
  .button {
    font-size: 18px;
    min-width: 150px;
 
  }

  body {
    overflow-x: hidden;
  }

  
  .buttonMap {
    zoom:1.3;
  }
}


 /*Ca sa nu apara underline la link-uri */
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }